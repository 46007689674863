<template>
<div class="under-progress-box-section d-flex justify-content-center" style="height: calc(100vh - 102px)">
<div class="align-self-center">
  <img
    src="/assets/images/um.png"  class="underprogress-page"
  />
</div>
</div>
</template>
<style scoped>
.underprogress-page{
    width: 550px;
}
.under-progress-box-section{
      background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 12px;
    border: 1px solid #E0E5ED;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 0;
    overflow-y: auto;
    margin-top: 15px;
}
</style>